import React from "react"
import Obfuscate from "react-obfuscate"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImgFluidWebp from "../components/img-fluid-webp"
import ImgFluid from "../components/img-fluid"

import "../scss/site.scss"
import "../scss/service.scss"

const LeistungBauphysik = () => {
  return (
    <Layout>
      <SEO
        title="Thermische Bauphysik | freie ingenieure bau"
        description="Unsere Leistungen: Nachweise nach EnEV und EEWärmeG, Prüfung von EnEV-Nachweisen, Abdichtungsplanung und -beratung, Gebäude- und Bauteilsimulation u.v.m."
        lang="de"
      />
      <div className="fib-sp80"></div>
      <div className="fib-sp40 uk-visible@m"></div>
      <div className="fib-container-text">
        <div className="uk-grid-medium uk-flex-top" uk-grid="">
          <div className="uk-width-8-12@s uk-width-7-12@m">
            <h2 className="fib-ft54 fib-ft-bold">
              Wir lösen Probleme, bevor sie entstehen.
            </h2>
            <h1 className="fib-ft54 fib-ft-bold fib-ftgrey-light">
              Energie ein&shy;sparen und Bau&shy;schäd&shy;en vermeiden.
            </h1>
          </div>
          <div className="fib-sp40 uk-hidden@m"></div>
          <div className="uk-width-5-12@m">
            <div className="uk-flex uk-flex-center">
              <div className="uk-width-8-12@s uk-width-10-12@m">
                <ImgFluid
                  className="fib-services-img-isobox"
                  filename="isobox-building-physics1.png"
                  alt="freie ingenieure bau - Isobox Thermische Baupyshik"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fib-sp120 uk-visible@m"></div>
        <div className="fib-sp120"></div>
        <div className="fib-services-divider-blue"></div>
        <div className="fib-sp20 uk-visible@m"></div>
        <div className="fib-sp60"></div>

        <div className="uk-grid-medium" uk-grid="">
          <div className="uk-width-7-12@s">
            <h3 className="fib-services-h3">Leistungen Thermische Bauphysik</h3>
            <h4 className="fib-ft20 fib-ft-bold fib-services-h4">
              Planung des Wärmeschutzes und der Energie&shy;bilan&shy;zierung
              inkl. Er&shy;wir&shy;kung von Be&shy;frei&shy;ungen und Ausnahmen
            </h4>
            <p>
              Um die stetig wachsenden Anfor&shy;derungen zur
              Energie&shy;einsparung mit den Projekt&shy;zielen in Einklang zu
              bringen gilt es, die relevanten Schnitt&shy;stellen zu den
              Planungs&shy;beteiligten genau zu kennen. Darüber hinaus gehen wir
              bei der Erstellung von Energie&shy;einspar&shy;nachweisen (GEG, EnEV,
              EEWärmeG) aktiv auf unsere Planungs&shy;partner zu, um so für alle
              Beteiligte frühzeitig die Planungs&shy;sicherheit und einen
              reibungs&shy;losen Projekt&shy;verlauf zu gewährleisten.
            </p>

            <h4 className="fib-ft20 fib-ft-bold fib-services-h4">
              Prüfung von GeG-(EnEV)-Nachweisen / Befreiungsanträgen
            </h4>
            <p>
              Zur Sicher&shy;stellung der energe&shy;tischen Qualität übernehmen
              wir für Sie die behördlich geforderte unabhängige Fachprüfung. Als
              von der Obersten Bauaufsicht Berlin anerkannter
              Prüf&shy;sachver&shy;ständiger erstellt Herr Krätschell die
              notwendige Prüf&shy;bestätigung für Neu- und Umbauten sowie die
              formale Freigabe von Befreiungs&shy;anträgen. In unserem Fokus
              steht hierbei der reibungslose Verlauf Ihres Vorhabens.
            </p>

            <h4 className="fib-ft20 fib-ft-bold fib-services-h4">
              Abdichtungsplanung und -beratung
            </h4>
            <p>
              Von der abdichtungs&shy;technischen Bausubstanz&shy;untersuchung
              bis hin zur Ausar&shy;beitung einer Detail&shy;planung erstellen
              wir bedarfs&shy;gerechte Lösungen. Durch umfangreiche Erfahrung
              aus der Überwachung von Abdichtungs&shy;maßnahmen können wir
              bereits in der Planung risiko&shy;reiche Schnitt&shy;stellen auf
              der Baustelle vermeiden. Mit Erfahrung zu dauerhaften
              Konstruk&shy;tionen.
            </p>

            <h4 className="fib-ft20 fib-ft-bold fib-services-h4">
              Gebäude- und Bauteilsimulation
            </h4>
            <p>
              Durch Simulationen erzielen wir für Sie mehr
              Planungs&shy;sicherheit, Kosten&shy;einsparung und die
              Sicher&shy;stellung der Gebrauchstaug&shy;lichkeit. Nach Analyse
              der konkreten Projekt&shy;ziele erfolgt die Auswahl des sinnvollen
              Umfangs. Ob Strömungs-, Raumklima- oder
              Wärme&shy;brücken&shy;simulation. Durch bereits vorliegende
              Berechnungs&shy;ergebnisse für Neu- und Bestands&shy;bauwerke
              stellt sich in Verbin&shy;dung mit analytische
              Bewertungs&shy;methoden oft heraus, dass für ihren
              Betrach&shy;tungs&shy;fall ein viel geringerer Umfang ausreicht,
              als zunächst erwartet.
            </p>

            <h4 className="fib-ft20 fib-ft-bold fib-services-h4">
              Weitere Leistungen
            </h4>
            <ul className="fib-list">
              <li>Bausubstanzanalyse</li>
              <li>Hygrothermische Nachweise für Innendämmungen</li>
              <li>Beurteilung des Schimmelpilzrisikos</li>
            </ul>
            <div style={{ paddingTop: `60px` }}></div>
          </div>

          <div className="uk-width-5-12@s">
            <div className="uk-flex uk-flex-center">
              <div className="uk-width-8-12@m">
                <ImgFluidWebp
                  filename="img-partner2-portrait.jpg"
                  alt="freie ingenieure bau - Dipl.-Ing. M. Krätschell"
                />
                <div style={{ paddingTop: `20px` }}></div>
                <p className="fib-ft18 fib-ft-bold">Dipl.-Ing. M. Krätschell</p>
                <p className="fib-ft18" style={{ fontStyle: `italic` }}>
                  Öffentlich bestell&shy;ter und ver&shy;eidig&shy;ter
                  Sach&shy;verstän&shy;diger für Schä&shy;den an Gebäu&shy;den
                  und Wärme- sowie Feuchte&shy;schutz,
                  Prüf&shy;sachver&shy;ständiger für ener&shy;getische
                  Ge&shy;bäude&shy;planung
                </p>
                <p className="fib-ft18 fib-ftgrey-dark">
                  <Obfuscate
                    className="fib-text-link fib-ft-blue"
                    email="kraetschell@freie-ingenieure-bau.de"
                  >
                    kraetschell@freie-ingenieure-bau.de
                  </Obfuscate>
                  <br />
                  <span itemProp="telephone">
                    <a
                      className="fib-text-link fib-ft-blue"
                      href="tel:+493061644837"
                    >
                      T +49 30 61644837
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="fib-sp80"></div>
        <div className="uk-grid " uk-grid="">
          <div className="uk-width-10-12@m">
            <ImgFluidWebp
              filename="bauphysik-klimastudie.jpg"
              alt="freie ingenieure bau - Klimastudie"
            />
          </div>
          <div className="uk-width-2-12@m uk-text-left">
            <p
              className="fib-ft18"
              style={{ fontStyle: `italic`, marginTop: `0px` }}
            >
              Klimastudie für den Außenbereich
            </p>
          </div>
        </div>
        <div className="fib-sp40 uk-visible@m"></div>
        <div className="fib-sp40"></div>

        <div className="uk-grid uk-grid-match uk-flex-bottom" uk-grid="">
          <div className="uk-grid-first-column uk-width-10-12@s uk-width-6-12@m">
            <div className="fib-services-3DBridge-wrapper">
              <div className="fib-services-3DBridge-pic">
                <ImgFluid
                  filename="bauphysik_3DBalkon_real_1"
                  alt="freie ingenieure bau - 3D Wärmebrücke pic"
                />
              </div>
              <div className="fib-services-3DBridge-iso">
                <ImgFluid
                  filename="bauphysik_3DBalkon_iso2_1"
                  alt="freie ingenieure bau - 3D Wärmebrücke iso"
                />
              </div>
              <div
                className="fib-ft18"
                style={{ fontStyle: `italic`, paddingTop: `40px` }}
              >
                Ermittlung von Oberflächen&shy;tempera&shy;turen und
                Schadens&shy;risiken an einer drei&shy;dimen&shy;sionalen
                Wärmebrücke
              </div>
            </div>
          </div>

          {/* <div className="uk-grid-first-column uk-width-10-12@s uk-width-6-12@m">
            <div className="uk-flex uk-flex-column uk-flex-top">
              <div className="uk-width-1-1">
                <ImgFluidWebp
                  style={{ padding: `20px` }}
                  filename="bauphysik-wbr-fenster1"
                  alt="freie ingenieure bau - Wärmebrücke Fenster"
                />
              </div>
              <div
                className="uk-width-6-12 uk-visible@m fib-ft18"
                style={{ fontStyle: `italic`, paddingTop: `80px` }}
              >
                Wärme&shy;brücken&shy;unter&shy;suchung an Fenster&shy;profilen
              </div>
            </div>
          </div> */}
          <div className="uk-width-10-12@s uk-width-6-12@m">
            <ImgFluid
              filename="bauphysik-wbr-fenster2"
              alt="freie ingenieure bau - Wärmebrücke Fenster"
            />
            <div
              className="fib-ft18"
              style={{ fontStyle: `italic`, paddingTop: `20px` }}
            >
              Wärme&shy;brücken&shy;unter&shy;suchung an Fenster&shy;profilen
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LeistungBauphysik
